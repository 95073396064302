import React, { useState, useCallback, useContext } from 'react';
import { mergeRefs } from 'use-callback-ref';
import { defineMessages, useIntl } from 'react-intl-next';

import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ModalTransition } from '@atlaskit/modal-dialog';

import { Profile } from '@atlassian/navigation-system/top-nav';

import { LoadableLazy } from '@confluence/loadable';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { useRouteDataRef } from '@confluence/route-manager';
import { preloadProfileMenu } from '@confluence/nav-menus/entry-points/preloadProfileMenu';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import {
	APP_NAV_PROFILE_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useTopNavigationStore } from '@confluence/top-navigation-store';

import type { TopNavigationQuery_user as User } from './__types__/TopNavigationQuery';

const ProfileMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ProfileMenu" */ '@confluence/nav-menus/entry-points/ProfileMenu'
			)
		).ProfileMenu,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireProfileClickedAnalytics,
);

type ProfileButtonProps = {
	user: Pick<User, 'photos'>;
};

export const ProfileButton = ({ user }: ProfileButtonProps) => {
	const routeDataRef = useRouteDataRef();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isExternalCollaborator } = useIsExternalCollaborator();
	const [isOpen, setIsOpen] = useState(false);
	const [activeDialog, setActiveDialog] = useState(null);
	const [, { setProfileMenuButtonRef }] = useTopNavigationStore();
	const fireClickedAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		routeDataRef.current?.routeName,
		isExternalCollaborator,
	);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const profileMenuButtonRef = (ref: HTMLButtonElement | null) => {
		setProfileMenuButtonRef(ref);
	};

	const profileIconUrl = user.photos?.[0]?.value || '';

	const onClick = () => {
		if (isOpen) {
			onClose();
		} else {
			setIsOpen(true);
		}

		void fireClickedAnalytics();
	};

	const onClose = () => {
		setIsOpen(false);

		experienceTracker.abort({
			name: APP_NAV_PROFILE_DROPDOWN_EXPERIENCE,
			reason: 'dropdown closed by user',
			attributes: {
				navVersion: '4',
			},
		});
	};

	const closeDialog = useCallback(() => {
		setActiveDialog(null);
	}, [setActiveDialog]);

	const openDialog = useCallback(
		(dialog: any) => {
			setActiveDialog(dialog(closeDialog));
		},
		[setActiveDialog, closeDialog],
	);

	const preloadMenu = useCallback(() => {
		void preloadProfileMenu();
		void ProfileMenuLoader.preload();
	}, []);

	return (
		<>
			<Popup
				isOpen={isOpen}
				onClose={onClose}
				content={({ update: scheduleUpdate }) => (
					<ProfileMenuLoader
						onClose={onClose}
						dialogFn={openDialog}
						scheduleUpdate={scheduleUpdate}
					/>
				)}
				trigger={(triggerProps) => (
					<>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div onMouseEnter={preloadMenu}>
							<Profile
								{...triggerProps}
								src={profileIconUrl}
								onClick={onClick}
								ref={mergeRefs([profileMenuButtonRef, triggerProps.ref])}
								label={
									isExternalCollaborator
										? intl.formatMessage(i18n.guestLabel)
										: intl.formatMessage(i18n.accountLabel)
								}
							/>
						</div>
					</>
				)}
				placement="bottom-end"
				shouldRenderToParent
			/>
			<ModalTransition>{activeDialog}</ModalTransition>
		</>
	);
};

const i18n = defineMessages({
	accountLabel: {
		id: 'app-navigation.top-navigation.account.label',
		defaultMessage: 'Account',
		description: 'Label for the top navigation profile dropdown button',
	},
	guestLabel: {
		id: 'app-navigation.top-navigation.profile.guest-label',
		defaultMessage: 'Account (Guest)',
		description: 'Label for the top navigation profile dropdown button on a Guest user',
	},
});
